﻿import { debounce, throttle } from "../utils/debounce";
import {
	expressAddClass,
	expressQuerySelector,
	expressQuerySelectorAll,
	expressRemoveClass,
	getHeightWithMargins,
} from "../common/html";
import { Guid } from "../utils/guid";
import { trackSliderEvent } from "../common/events";


export interface ICustomPhotoSliderItem {
	id?: string;
	template?: HTMLElement;
}

export interface IButtonElement {
	text?: string;
	css?: string;
	cssWhenOnMax?: string;
	onClick(e: Event, draggedSliderEventTracking: boolean): void;
}

export interface ISliderElementSettings {
	extraSliderClass?: string;
	slideClicked?(e: Event, settings: ICustomPhotoSliderWithDivSettings): void;
}

export interface ISliderElementContainerSettings {
	extraSliderClass?: string;
	elementsContainerSettings: ISliderElementSettings;
	cssWhenButtonsBothOnMax?: string;
	nextButton?: IButtonElement;
	previousButton?: IButtonElement;
}
export enum EternalSlidingMode {
	Infinite,
	ScrollToBegin
}

export interface ICustomPhotoSliderWithDivSettings {
	sliderElements: ICustomPhotoSliderItem[];
	sliderContainerSettings: ISliderElementContainerSettings;
	showHalfSlides?: boolean;
	replaceWithElement?: boolean;
	// false if the slider should not continue when at end
	enableEternalSliding?: boolean;
	// Mode that is used once the enableEternalSliding param is enabled => by default Infinite if nothing is passed
	eternalSlidingMode?: EternalSlidingMode;
	translateForMovement?: boolean;
	// don't wait on IntersectionObserver to render the slider
	forceRenderSlider?: boolean;

	// custom events
	SliderCreated?: (settings: ICustomPhotoSliderWithDivSettings, slider: HTMLElement, oldSlider: HTMLElement | undefined) => void;
	InitializedEvent?: (settings: ICustomPhotoSliderWithDivSettings, slider: HTMLElement) => void;
	ResizeEvent?: (settings: ICustomPhotoSliderWithDivSettings, slider: HTMLElement) => void;
	HeightScriptButtons?: (slider: HTMLElement, bottomTitleHeight: number, highestSlideEl: HTMLElement) => void;
}

export interface ICustomSlider {
	RemoveSliderElement(sliderElement: ICustomPhotoSliderItem, indexToRemove?: number): void;
	RemoveSliderElementOnIndex(indexToRemove: number): void;
	AddSliderElement(sliderElement: ICustomPhotoSliderItem, indexToInsert: number): void;
	Previous(): void;
	Next(): void;
	JumpIntoView(sliderElement: ICustomPhotoSliderItem, smoothSlide?: boolean, align?: string): void;
	Settings: ICustomPhotoSliderWithDivSettings;
	GetCurrentSlide(): ICustomPhotoSliderItem | undefined;
	GetAllSlides(): ICustomPhotoSliderItem[];
	GetSliderElement(): HTMLElement;
	ShowButtons(): void;
	HideButtons(): void;
	Dispose(): void;
	AddHandlers(): void;
}


// Gets the highest slide element in the slider elements
const getHighestSlideOfSlider = (slidesInSlider: HTMLElement[]): HTMLElement => {
	let highestSlideEl: HTMLElement;
	let maxTitleHeight = 0;

	for (let i = slidesInSlider.length - 1; i >= 0; i--) {
		const slideEl = slidesInSlider[i] as HTMLElement;
		const sliderTitleEl = expressQuerySelector<HTMLElement>(slideEl, '.technical-slide-title', false);
		const tileTitle = expressQuerySelector<HTMLElement>(slideEl, '.technical-tile-title', false);
		const tileSubTitle = expressQuerySelector<HTMLElement>(slideEl, '.technical-tile-subtitle', false);

		const titleHeight = tileTitle ? getHeightWithMargins(tileTitle) : 0;
		const subTitleHeight = tileSubTitle ? getHeightWithMargins(tileSubTitle) : 0;
		const titleheight = titleHeight + subTitleHeight;

		// reset height
		if (sliderTitleEl)
			sliderTitleEl.style.height = '';

		if (maxTitleHeight <= titleheight) {
			maxTitleHeight = titleheight;
			highestSlideEl = slideEl;
		}
	}

	return highestSlideEl!;
};

export const SetHeight = (settings: ICustomPhotoSliderWithDivSettings, slider: HTMLElement, oldSlider?: HTMLElement) => {
	const visibleSlides = expressQuerySelectorAll(slider, '.technical-slide') as HTMLElement[];
	const highestSlideEl = getHighestSlideOfSlider(visibleSlides);
	const highestSlideTitle = expressQuerySelector<HTMLElement>(highestSlideEl, '.technical-tile-title', false);
	const highestSlideSubTitle = expressQuerySelector<HTMLElement>(highestSlideEl, '.technical-tile-subtitle', false);
	const highestSlideLinkText = expressQuerySelector<HTMLElement>(highestSlideEl, '.technical-tile-link-text', false);

	const highestSlideTitlepx = highestSlideTitle ? getHeightWithMargins(highestSlideTitle) : 0;
	const highestSlideSubTitlepx = highestSlideSubTitle ? getHeightWithMargins(highestSlideSubTitle) : 0;
	const highestSlideLinkTextpx = highestSlideLinkText ? getHeightWithMargins(highestSlideLinkText) : 0;
	const highestSlideFullTitlepx = highestSlideTitlepx + highestSlideSubTitlepx;

	if (highestSlideFullTitlepx > 0) {
		for (let i = 0, l = visibleSlides.length; i < l; i++) {
			const slideFullTitleEl = expressQuerySelector<HTMLElement>(visibleSlides[i] as HTMLElement, '.technical-slide-title', false);

			if (slideFullTitleEl && highestSlideFullTitlepx > 0)
				slideFullTitleEl.style.height = `${highestSlideFullTitlepx}px`;
		}
	}

	settings.HeightScriptButtons(slider, highestSlideLinkTextpx, highestSlideEl);
};

const CustomPhotoSliderWithDivSettingsDefaults: ICustomPhotoSliderWithDivSettings = {
	sliderElements: [],
	sliderContainerSettings: {
		elementsContainerSettings: {
			extraSliderClass: '',
			slideClicked: () => { return; }
		},
		cssWhenButtonsBothOnMax: 'u-hide',
		nextButton: {
			css: 'a-button a-button--slider-button m-product-slider__button--next icon-arrow-right technical-slider-button',
			cssWhenOnMax: 'a-button--disabled',
			onClick: (_: Event, draggedSlider: boolean) => {
				trackSliderEvent(`nextbutton_${draggedSlider ? 'dragged' : 'clicked'}`);
				return;
			}
		},
		previousButton: {
			css: 'a-button a-button--slider-button m-product-slider__button--prev icon-arrow-left technical-slider-button',
			cssWhenOnMax: 'a-button--disabled',
			onClick: (_: Event, draggedSlider: boolean) => {
				trackSliderEvent(`prevbutton_${draggedSlider ? 'dragged' : 'clicked'}`);
				return;
			}
		},
	},
	showHalfSlides: false,
	replaceWithElement: true,
	enableEternalSliding: true,
	eternalSlidingMode: EternalSlidingMode.Infinite,
	translateForMovement: true,
	forceRenderSlider: true, // selecting a format image on mobile then go to desktop => format image not there when false


	// height script buttons
	SliderCreated: (settings, slider, oldSlider) => {
		setTimeout(() => {
			SetHeight(settings, slider, oldSlider);
		}, 0); 
	},
	ResizeEvent: (settings, slider) => {
		SetHeight(settings, slider);
	},

	HeightScriptButtons: (slider: HTMLElement, bottomTitleHeight: number, highestSlideEl: HTMLElement) => {
		const sliderButtons = expressQuerySelectorAll<HTMLElement>(slider, '.technical-slider-button');
		const hightestImage = expressQuerySelector<HTMLElement>(highestSlideEl, '.technical-slide-imageContainer', false);
		let buttonCenterImage = 0;
		if (hightestImage) {
			const btnHeight = sliderButtons[0].offsetHeight;
			buttonCenterImage = (hightestImage.offsetHeight / 2) + bottomTitleHeight - (btnHeight / 2);
		}

		for (let i = 0, l = sliderButtons.length; i < l; i++) {
			(sliderButtons[i] as HTMLElement).style.bottom = `${buttonCenterImage > 0 ? buttonCenterImage : (highestSlideEl.offsetHeight / 2)}px`;
		}
	},
};



export function createCustomSlider(element: HTMLElement, settings: ICustomPhotoSliderWithDivSettings): ICustomSlider {

	settings = {
		...CustomPhotoSliderWithDivSettingsDefaults, ...settings,
		sliderContainerSettings: {
			...CustomPhotoSliderWithDivSettingsDefaults.sliderContainerSettings, ...settings.sliderContainerSettings,
			elementsContainerSettings: {
				...CustomPhotoSliderWithDivSettingsDefaults.sliderContainerSettings.elementsContainerSettings, ...settings.sliderContainerSettings.elementsContainerSettings,
			},
			nextButton: {
				...CustomPhotoSliderWithDivSettingsDefaults.sliderContainerSettings.nextButton, ...settings.sliderContainerSettings.nextButton,
			},
			previousButton: {
				...CustomPhotoSliderWithDivSettingsDefaults.sliderContainerSettings.previousButton, ...settings.sliderContainerSettings.previousButton,
			},
		},
	};

	// prop to move div from left to right
	let sliderPxX = 0;
	// slider elements
	let sliderEl: HTMLElement;
	let elementsEl: HTMLElement;
	let firstVisibleElement: HTMLElement | undefined;
	let prevButton: HTMLElement;
	let nextButton: HTMLElement;
	// properties for sliding
	let mouseDownOnSlider = false;
	let startSliderDragX = 0;
	let currentSliderDragX = 0;
	let isDragged = false;

	let preventPageScrolling = false;
	let preventSliderDragging = false;
	let prevSliderWidth = 0;

	// private methods
	const init = function (element: HTMLElement, replaceWithElement: boolean) {
		if (ioSlider && !settings.forceRenderSlider) {
			ioSlider.observe(element);
		} else {
			// create slider template
			sliderEl = getSliderTemplate();

			// append slider to HTML
			if (replaceWithElement && element.parentElement)
				element.parentElement.replaceChild(sliderEl, element);
			else
				element.appendChild(sliderEl);

			// trigger initialized event
			if (settings.InitializedEvent) settings.InitializedEvent(settings, sliderEl);

			// to check if the buttons should be disabled or not
			checkEdgesAndMove(0);

			addHandlers();
		}
	};

	const dispose = () => {
		prevButton && prevButton.removeEventListener('click', previousEvent);
		nextButton && nextButton.removeEventListener('click', nextEvent);
		if (sliderEl) {
			sliderEl.removeEventListener('mousedown', (e) => {
				e.preventDefault();
				dragStart(e);
			});
			sliderEl.removeEventListener('touchstart', dragStart);

			if (isTouchDevice()) {
				sliderEl.removeEventListener('touchend', dragStop);
				sliderEl.removeEventListener('touchmove', dragging);
				sliderEl.removeEventListener('touchcancel', dragLeave);
				return;
			}

			sliderEl.removeEventListener('mousemove', dragging);
			sliderEl.removeEventListener('mouseleave', dragLeave);
			sliderEl.removeEventListener('mouseup', dragStop);

		}
		if (window) {
			window.removeEventListener('scroll', scrollStart);
			window.removeEventListener('resize', resize);
		}
	};

	const addHandlers = () => {
		prevButton && prevButton.addEventListener('click', previousEvent);
		nextButton && nextButton.addEventListener('click', nextEvent);

		// add event listeners of slider
		addSliderEventListeners();

		// add external events
		externalEventListeners();
	};

	// #region SliderEvents
	const isTouchDevice = () => ('ontouchstart' in window) || (navigator.maxTouchPoints > 0);

	const addSliderEventListeners = () => {
		// add touch listeners when touch
		if (isTouchDevice())
			sliderTouchEventListeners();
		sliderMouseEventListeners();
	};
	const sliderMouseEventListeners = () => {
		// mouse events
		sliderEl.addEventListener('mousedown', (e) => {
			e.preventDefault();
			dragStart(e);
		});
	};
	const sliderTouchEventListeners = () => {
		// touch events
		// passive => opt-in to better scroll performance by eliminating the need for scrolling to block on touch and wheel event listeners
		sliderEl.addEventListener('touchstart', dragStart, { passive: true });
		window.addEventListener('scroll', scrollStart);
	};
	const resize = () => {
		// Android/IOS triggers resize caused by scrolling (navigation bar goes away) => don't change anything when slider width is the same
		const sliderWidth = getFullWidthElement(sliderEl);
		if (prevSliderWidth === sliderWidth) return;

		prevSliderWidth = sliderWidth;

		debounce(reset, 250)();
	};
	const externalEventListeners = () => {
		window && window.addEventListener('resize', resize);
	};
	const preventClick = (e: Event) => {
		e.preventDefault();
		e.stopImmediatePropagation();
	};
	const scrollStart = () => {
		preventSliderDragging = true;
	};
	// drag methods
	const dragStart = (e: Event) => {
		isDragged = false;
		mouseDownOnSlider = true;
		startSliderDragX = getPositionX(e);
		currentSliderDragX = startSliderDragX;
		preventPageScrolling = false;
		preventSliderDragging = nextButton.classList.contains(settings.sliderContainerSettings.nextButton.cssWhenOnMax) && prevButton.classList.contains(settings.sliderContainerSettings.previousButton.cssWhenOnMax);

		if (isTouchDevice()) {
			sliderEl.addEventListener('touchend', dragStop, { passive: true });
			sliderEl.addEventListener('touchmove', throttle(dragging, 100), { passive: false });
			sliderEl.addEventListener('touchcancel', dragLeave, { passive: true });
			return;
		}

		// mouse events
		sliderEl.addEventListener('mousemove', (e) => {
			if (!mouseDownOnSlider) return;
			e.preventDefault();
			dragging(e);
		});
		sliderEl.addEventListener('mouseleave', dragLeave, { passive: true });
		sliderEl.addEventListener('mouseup', dragStop, { passive: true });
	};
	const dragLeave = (e: Event) => {
		if (!mouseDownOnSlider) return;
		mouseDownOnSlider = false;
		elementsEl.parentElement.style.scrollBehavior = "smooth";

		// Move the next/previous slider fully into view
		if (startSliderDragX < currentSliderDragX)
			previousEvent(e, false, true);
		else if (startSliderDragX > currentSliderDragX)
			nextEvent(e, false, true);
	};
	const dragging = (e: Event) => {
		if (!mouseDownOnSlider || preventSliderDragging) return;

		elementsEl.parentElement.style.scrollBehavior = 'auto';

		const posX = getPositionX(e);
		sliderPxX += Math.round(posX - currentSliderDragX);

		// Stop page scrolling if your finger moves more than 10 pixels left or right
		const movedSinceStart = posX - startSliderDragX;
		isDragged = Math.abs(movedSinceStart) > 10;
		if (Math.abs(movedSinceStart) > 10)
			preventPageScrolling = true;

		if (preventPageScrolling)
			e.preventDefault();

		if (settings.enableEternalSliding
			&& (settings.eternalSlidingMode || EternalSlidingMode.Infinite) === EternalSlidingMode.Infinite)
			debounce(_ => {
				if (currentSliderDragX === posX) return;

				const element = getFirstVisibleElement();
				const elementWidth = getFullWidthElement(element);
				const visibleElements = Math.ceil(getFullWidthElement(sliderEl) / elementWidth);
				const sliderxPassedTiles = -sliderPxX / elementWidth;

				const direction = currentSliderDragX > posX ? 'left' : 'right';

				if (direction === 'right' && (sliderxPassedTiles + visibleElements >= settings.sliderElements.length)) {
					elementsEl.appendChild(elementsEl.children[0]); // add to right
					sliderPxX += elementWidth;
					moveSlider(true); // should not move slider

				} else if (direction === 'left' && (sliderxPassedTiles < 0.2)) {
					elementsEl.prepend(elementsEl.children[elementsEl.children.length - 1]); // add to left
					sliderPxX += -elementWidth;
					moveSlider(true); // should not move slider
				}
			}, 250)();

		// set new location here
		currentSliderDragX = posX;

		// move without check => check when stopped
		moveSlider(true);
	};
	const dragStop = (e: Event) => {
		mouseDownOnSlider = false;
		elementsEl.parentElement.style.scrollBehavior = "smooth";

		// enable/disable click event on slider-element when dragging
		if (!isDragged && e.target && !(e.target as HTMLElement).classList.contains('technical-slider-button'))
			settings.sliderContainerSettings.elementsContainerSettings.slideClicked(e, settings);

		const slideElements = expressQuerySelectorAll(elementsEl, '.technical-slider-element');
		const elMethod = isDragged ? 'addEventListener' : 'removeEventListener';
		slideElements.forEach(el => el[elMethod]('click', preventClick));

		// Move the next/previous slider fully into view
		if (startSliderDragX < currentSliderDragX)
			previousEvent(e, false, true);
		else if (startSliderDragX > currentSliderDragX)
			nextEvent(e, false, true);

		// touch events
		if (isTouchDevice()) {
			sliderEl.removeEventListener('touchend', dragStop);
			sliderEl.removeEventListener('touchmove', dragging);
			sliderEl.removeEventListener('touchcancel', dragLeave);
			return;
		}

		// remove mouse events
		sliderEl.removeEventListener('mousemove', dragging);
		sliderEl.removeEventListener('mouseleave', dragLeave);
		sliderEl.removeEventListener('mouseup', dragStop);

		isDragged = false;
	};
	const getPositionX = (e) => {
		return e.type.includes('mouse') ? e.pageX : e.touches[0].pageX;
	};
	// #endregion SliderEvents
	const getSliderTemplate = (): HTMLElement => {
		if (settings.sliderElements.length === 0) return document.createElement('span');

		// generate div to hold slider
		const div = document.createElement('div');
		div.className += settings.sliderContainerSettings.extraSliderClass;

		// prev
		prevButton = document.createElement('a');
		prevButton.textContent = settings.sliderContainerSettings.previousButton.text ? settings.sliderContainerSettings.previousButton.text : '';
		prevButton.title = "previous";

		// prev.style
		prevButton.className += settings.sliderContainerSettings.previousButton.css;
		prevButton.classList.add(settings.sliderContainerSettings.previousButton.cssWhenOnMax);
		div.appendChild(prevButton);

		// images
		elementsEl = document.createElement('div');
		elementsEl.className += settings.sliderContainerSettings.elementsContainerSettings.extraSliderClass;
		elementsEl.classList.add('technical-slider-elements');

		for (let i = 0, l = settings.sliderElements.length; i < l; i++) {
			const customPhotoSliderItem = settings.sliderElements[i];
			const sliderElementDiv = createSliderElement(customPhotoSliderItem);
			elementsEl.appendChild(sliderElementDiv);
		}

		// append to elementsDiv
		div.appendChild(elementsEl);

		// next
		nextButton = document.createElement('a');
		nextButton.textContent = settings.sliderContainerSettings.nextButton.text ? settings.sliderContainerSettings.nextButton.text : '';
		prevButton.title = "next";

		// next.style
		nextButton.className += settings.sliderContainerSettings.nextButton.css;
		div.appendChild(nextButton);


		settings.SliderCreated && settings.SliderCreated(settings, div, sliderEl);
		// return div element
		return div;
	};
	const getCurrentElement = (): ICustomPhotoSliderItem | undefined => {
		const elementEl = getFirstVisibleElement();
		if (!elementEl) return; // if no element visible return
		const elementElWidth = getFullWidthElement(elementEl);
		const currentElementIndex = elementElWidth <= 0 ? 0 : Math.floor(Math.abs(sliderPxX) / elementElWidth);
		const index = currentElementIndex % elementsEl.children.length;
		return settings.sliderElements.find(x => elementsEl.children[index].id === x.id);
	};
	const getAllElements = (): ICustomPhotoSliderItem[] => {
		const slides = Array.from(elementsEl.children);
		return [...settings.sliderElements].sort((a, b) => {
			const elementA = slides.find(e => e.id === a.id);
			const elementB = slides.find(e => e.id === b.id);
			return slides.indexOf(elementA) - slides.indexOf(elementB);
		}); //sort a copy of the list
	};
	const checkEdgesAndMove = (movePx: number, draggedSlider?: boolean, jumpIntoView = false) => {
		// if beginning or end stop
		sliderPxX += movePx;
		let prevButtonClasslistMethod = 'add';
		let nextButtonClasslistMethod = 'add';
		const element = getFirstVisibleElement();
		const elementWidth = getFullWidthElement(element);
		let moveToOtherside = false;

		const endOfSlider = () => getFullWidthElement(sliderEl) - (getFullWidthElement(element) * settings.sliderElements.length);
		const widthRightSidePx = () => (getFullWidthElement(element) * settings.sliderElements.length) - Math.abs(sliderPxX);

		if (sliderPxX >= 0) { // test negative scroll
			nextButtonClasslistMethod = 'remove';
			if (settings.enableEternalSliding) prevButtonClasslistMethod = 'remove';
			if (settings.enableEternalSliding && !jumpIntoView && sliderPxX > 0) {
				switch (settings.eternalSlidingMode) {
					case EternalSlidingMode.ScrollToBegin:
						moveToOtherside = true;
						sliderPxX = endOfSlider();
						break;
					case EternalSlidingMode.Infinite:
					default:
						elementsEl.prepend(elementsEl.children[elementsEl.children.length - 1]);
						moveSlider(true, false, -sliderPxX); // update HTML with current pxX after move => should not move visually
						sliderPxX = 0;
						break;
				}
			} else sliderPxX = 0;
		} else if (widthRightSidePx() <= getFullWidthElement(sliderEl)) { // test positive scroll
			prevButtonClasslistMethod = 'remove';
			if (settings.enableEternalSliding) nextButtonClasslistMethod = 'remove';
			if (settings.enableEternalSliding && !jumpIntoView && widthRightSidePx() < getFullWidthElement(sliderEl)) {
				switch (settings.eternalSlidingMode) {
					case EternalSlidingMode.ScrollToBegin:
						moveToOtherside = true;
						sliderPxX = 0;
						break;
					case EternalSlidingMode.Infinite:
					default:
						elementsEl.appendChild(elementsEl.children[0]);
						moveSlider(true, false, sliderPxX + Math.abs(movePx) + elementWidth); // update HTML with current pxX after move => should not move visually
						sliderPxX = endOfSlider();
						break;
				}
			} else sliderPxX = endOfSlider();
		} else { // the sroll is not over positive or over negative
			prevButtonClasslistMethod = 'remove';
			nextButtonClasslistMethod = 'remove';
		}

		// check if images div is greater then px width
		if (getFullWidthElement(sliderEl) >= (getFullWidthElement(element) * settings.sliderElements.length)) {
			nextButtonClasslistMethod = 'add';
			prevButtonClasslistMethod = 'add';
		}

		if (settings.sliderContainerSettings.cssWhenButtonsBothOnMax) {
			if (prevButton) prevButton.classList.toggle(settings.sliderContainerSettings.cssWhenButtonsBothOnMax, nextButtonClasslistMethod === 'add' && prevButtonClasslistMethod === 'add');
			if (nextButton) nextButton.classList.toggle(settings.sliderContainerSettings.cssWhenButtonsBothOnMax, nextButtonClasslistMethod === 'add' && prevButtonClasslistMethod === 'add');
		}

		// add or remove class from classlist depending on ifs
		if (prevButton) prevButton.classList[prevButtonClasslistMethod](settings.sliderContainerSettings.previousButton.cssWhenOnMax);
		if (nextButton) nextButton.classList[nextButtonClasslistMethod](settings.sliderContainerSettings.nextButton.cssWhenOnMax);

		if (prevButton || nextButton)
			moveSlider(draggedSlider, moveToOtherside);
	};
	const moveSlider = (draggedSlider?: boolean, moveToOtherside = false, sliderPxXOverride?: number) => {
		const ms = moveToOtherside ? elementsEl.children.length * 250 : 200;
		elementsEl.style.transition = draggedSlider ? 'none' : `all ${ms}ms ease-in-out 0s`;

		const pixelX = sliderPxXOverride || sliderPxXOverride >= 0 ? sliderPxXOverride : sliderPxX;
		if (settings.translateForMovement) {
			elementsEl.style.transform = `translateX(${pixelX}px)`;
		} else {
			elementsEl.parentElement && elementsEl.parentElement.scroll({
				top: 0,
				left: -pixelX,
				// @ts-ignore https://developer.mozilla.org/en-US/docs/Web/API/Window/scroll => instant is a behavior that is supported
				behavior: draggedSlider ? 'instant' : 'smooth'
			});
		}
	};
	const createSliderElement = (customPhotoSliderItem: ICustomPhotoSliderItem): HTMLElement => {
		if (!customPhotoSliderItem.id)
			customPhotoSliderItem.id = Guid.newSliderGuid();

		// elements
		const sliderElement = customPhotoSliderItem.template ? customPhotoSliderItem.template.cloneNode(true) as HTMLElement : document.createElement('div');
		sliderElement.id = customPhotoSliderItem.id;
		sliderElement.classList.add('technical-slider-element');

		//return div
		return sliderElement;
	};
	const removeImageFromArray = (indexToRemove: number) => {
		if (!settings.sliderElements || indexToRemove > settings.sliderElements.length - 1 || indexToRemove < 0 || !settings.sliderElements[indexToRemove])
			throw Error('Something is wrong with your current parameters');
		else {
			let tempArray = settings.sliderElements.slice(0, indexToRemove);

			// if last item remove this is faster
			if (tempArray.length === settings.sliderElements.length - 1) {
				settings.sliderElements = tempArray;
				return;
			}

			tempArray = tempArray.concat(settings.sliderElements.slice(indexToRemove + 1));
			settings.sliderElements = tempArray;
		}
	};
	const insertIntoSliderElements = (sliderElement: ICustomPhotoSliderItem, indexToInsert: number) => {
		// if images.length => just push
		if (indexToInsert === settings.sliderElements.length) {
			settings.sliderElements.push(sliderElement);
			return;
		}

		// if 0 add to new array copy old array into new array => change array
		if (indexToInsert === 0) {
			const tempArray: ICustomPhotoSliderItem[] = [sliderElement];
			settings.sliderElements = tempArray.concat(settings.sliderElements);
			return;
		}

		// insert in between
		let tempArray = settings.sliderElements.slice(0, indexToInsert);
		tempArray.push(sliderElement);
		tempArray = tempArray.concat(settings.sliderElements.slice(indexToInsert));

		settings.sliderElements = tempArray;
	};
	const calculateNextMove = function (positiveMove: boolean): number {
		const elementEl = getFirstVisibleElement();

		// if no slider just skip
		if (settings.sliderElements.length <= 1 || !elementEl)
			return 0;

		// !!! WARNING !!! solution for all same width elements !!!
		const elementElWidth = getFullWidthElement(elementEl);

		if (Math.ceil(getFullWidthElement(elementsEl)) / elementElWidth < 1)
			return elementElWidth * (positiveMove ? -0.5 : 0.5); // default 0.50 % of slide move

		// calculate sliderElement.width / width elements => get % of visible slide on each side
		const pxRight = (elementElWidth * settings.sliderElements.length) - (Math.ceil(getFullWidthElement(sliderEl)) + Math.abs(sliderPxX));
		const maxPossibleSliderItemsRightPx = pxRight / elementElWidth;
		const maxPossibleSliderItemsLeftpx = Math.abs(sliderPxX) / elementElWidth;

		let percentage = positiveMove
			? -(maxPossibleSliderItemsRightPx - Math.floor(maxPossibleSliderItemsRightPx))
			: maxPossibleSliderItemsLeftpx - Math.floor(maxPossibleSliderItemsLeftpx);

		// Floating point arithmetic is not always 100% accurate
		percentage = parseInt(`${percentage * 10000}`) / 10000;

		// for the case that the slide is exactly on the page
		// or slider the slide is already almost fully visitable and you want to go to the next slide
		if (percentage === 0 || Math.abs(percentage) <= 0.08)
			percentage += positiveMove ? -1 : 1;

		// if half slides have to be shown
		if (settings.showHalfSlides)
			percentage *= 1.5;

		// calculate how much % the slide needs to be completely shown
		return elementElWidth * percentage;
	};
	const jumpIntoView = (sliderElement: ICustomPhotoSliderItem, smoothSlideIntoView = true, align: 'start' | 'end' = 'start') => {
		const elements = getAllElements();
		let indexOfElement = elements.indexOf(sliderElement);
		if (indexOfElement < 0)
			return;

		const visibleEl = getFirstVisibleElement(); // this slide is already visible => no width of 0
		const element = expressQuerySelector<HTMLElement>(elementsEl, `#${visibleEl.id}`, true);
		const elementWidth = getFullWidthElement(element);

		let moveToLocation;
		switch (settings.eternalSlidingMode) {
			case EternalSlidingMode.ScrollToBegin:
				moveToLocation = (elementWidth * indexOfElement) - (align == 'start' ? 0 : (elementWidth * ((getFullWidthElement(sliderEl) / elementWidth) - 1))); // -1 because this is a count, not an index
				break;

			case EternalSlidingMode.Infinite:
			default: {
				const currentSlide = getCurrentElement();
				const currentSlideIndex = elements.indexOf(currentSlide);
				const visibleElements = Math.ceil(getFullWidthElement(sliderEl) / elementWidth);

				switch (align) {
					case 'start':
						{
							if (currentSlideIndex > indexOfElement) break;
							const elementsToMoveToFront = [...elements].splice(currentSlideIndex + visibleElements);

							elementsToMoveToFront.forEach((_, i) => {
								elementsEl.prepend(elementsEl.children[elementsEl.children.length - 1]);
								sliderPxX -= elementWidth;
								moveSlider(true, false); // update HTML with current pxX after move => should not move visually
							});

							break;
						}
					case 'end':
					default:
						{
							if (currentSlideIndex < indexOfElement) break;
							const elementsToMoveToBack = [...elements].splice(currentSlideIndex);

							elementsToMoveToBack.forEach((_, i) => {
								elementsEl.appendChild(elementsEl.children[0]);
								sliderPxX += elementWidth;
								moveSlider(true, false); // update HTML with current pxX after move => should not move visually
							});

							break;
						}
				}

				indexOfElement = getAllElements().indexOf(sliderElement); // recalculate index after move
				moveToLocation = (elementWidth * indexOfElement) - (align == 'start' ? 0 : (elementWidth * ((getFullWidthElement(sliderEl) / elementWidth) - 1))); // -1 because this is a count, not an index

				break;
			}
		}

		// update item
		const movePx = moveToLocation > Math.abs(sliderPxX) ? -moveToLocation - sliderPxX : Math.abs(sliderPxX) - moveToLocation;
		checkEdgesAndMove(movePx, !smoothSlideIntoView, true);
	};
	const getFirstVisibleElement = (): HTMLElement | undefined => {
		if (!elementsEl) return;
		if (firstVisibleElement) return firstVisibleElement;
		for (let i = 0; i < elementsEl.children.length; i++) {
			const element = elementsEl.children[i];
			if (element && element.id) {
				const htmlElement = expressQuerySelector<HTMLElement>(elementsEl, `#${element.id}`, true);

				if (getFullWidthElement(htmlElement) > 0 && htmlElement.clientWidth > 0) {
					firstVisibleElement = htmlElement;
					return htmlElement;
				}
			}
		}

		// return default
		return undefined;
	};
	const getFullWidthElement = (element: HTMLElement | undefined): number => {
		if (!element) return 0;
		const style = window.getComputedStyle(element),
			width = style.width ? parseFloat(style!.width) : element.clientWidth, // style.width already had the border in the calculation
			margin = parseFloat(style.marginLeft ? style.marginLeft : '0') + parseFloat(style.marginRight ? style.marginRight : '0'),
			padding = parseFloat(style.paddingLeft ? style.paddingLeft : '0') + parseFloat(style.paddingRight ? style.paddingRight : '0');

		return Math.ceil((width + (margin ? margin : 0) + (padding ? padding : 0))); // Math.ceil to get a rounded number => Firefox, Edge don't work with decimals
	};
	// public methods
	const removeSliderElement = (sliderElement: ICustomPhotoSliderItem, indexToRemove?: number) => {
		// check params
		if ((!indexToRemove && settings.sliderElements.filter(x => x.id === sliderElement).length < 0) || (indexToRemove && (indexToRemove < 0 || indexToRemove > settings.sliderElements.length - 1)))
			throw Error('Index to remove not found');

		// remove image
		removeImageFromArray(indexToRemove ? indexToRemove : settings.sliderElements.indexOf(sliderElement));

		// reset firstVisibleElement
		firstVisibleElement = undefined;

		// Manipulate dom
		if (!sliderElement.id)
			throw Error('invalid image id');

		// search image el to delete
		const deleteImageEl = expressQuerySelector(elementsEl, `#${sliderElement.id}`, true);
		elementsEl.removeChild(deleteImageEl);

		// check edges over invalid sliderPxX
		checkEdgesAndMove(0);
	};
	const removeSliderElementOnIndex = (indexToRemove: number) => {
		// check params
		if ((indexToRemove < 0 || indexToRemove > settings.sliderElements.length - 1)) throw Error('Index to remove not found');

		// remove image
		const deletedElement = settings.sliderElements[indexToRemove];
		removeSliderElement(deletedElement, indexToRemove);
	};
	const addSliderElement = (sliderElement: ICustomPhotoSliderItem, indexToInsert: number = settings.sliderElements.length) => {
		// id check for UI
		// if id already found generate new one => no bugs in dots this way
		if (!sliderElement.id || settings.sliderElements.find(x => x.id === sliderElement.id))
			sliderElement.id = Guid.newSliderGuid();

		// insert element in slider on index
		const element = indexToInsert === settings.sliderElements.length
			? settings.sliderElements[settings.sliderElements.length - 1]
			: settings.sliderElements[indexToInsert];

		insertIntoSliderElements(sliderElement, indexToInsert);
		const prevElement = expressQuerySelector(elementsEl, `#${element.id}`, true);
		const newSlideElement = createSliderElement(sliderElement);

		indexToInsert === settings.sliderElements.length
			? elementsEl.insertAdjacentElement("afterend", newSlideElement)
			: elementsEl.insertBefore(newSlideElement, prevElement);

		jumpIntoView(sliderElement);
	};
	const reset = () => {
		checkEdgesAndMove(0);
		settings.ResizeEvent && settings.ResizeEvent(settings, sliderEl);
	};
	const previousEvent = (e?: Event, draggedSlider = false, draggedSliderEventTracking = false) => {
		if (!settings.enableEternalSliding) checkEdgesAndMove(0);
		if (prevButton.classList.contains(settings.sliderContainerSettings.previousButton.cssWhenOnMax)) return;

		// calculate next move
		const nextMove = calculateNextMove(false);
		// update item
		checkEdgesAndMove(nextMove, draggedSlider);

		// on click event of settings
		settings.sliderContainerSettings.previousButton.onClick(e, draggedSliderEventTracking);
	};
	const nextEvent = (e?: Event, draggedSlider = false, draggedSliderEventTracking = false) => {
		if (!settings.enableEternalSliding) checkEdgesAndMove(0);
		if (nextButton.classList.contains(settings.sliderContainerSettings.nextButton.cssWhenOnMax)) return;

		// calculate next move
		const nextMove = calculateNextMove(true);
		// update item
		checkEdgesAndMove(nextMove, draggedSlider);

		// on click event of settings
		settings.sliderContainerSettings.nextButton.onClick(e, draggedSliderEventTracking);
	};
	const createIntersectionObserver = () => {
		if (('InterSectionObserver' in window || 'IntersectionObserverEntry' in window && 'intersectionRatio' in (window as any).IntersectionObserverEntry.prototype)) {
			return new IntersectionObserver((entries, observer) => {
				entries.forEach(entry => {
					if (entry.isIntersecting && entry.target.classList.contains(element.classList[0])) {
						ioSlider = undefined;
						// init slider when in parent element in view
						init(element, settings.replaceWithElement);
						observer.unobserve(entry.target);
					}
				});
			},
				{ rootMargin: "0px 0px 0px 0px" }
			);
		}
	};

	const showButtons = () => {
		nextButton && expressRemoveClass(nextButton, settings.sliderContainerSettings.cssWhenButtonsBothOnMax);
		prevButton && expressRemoveClass(prevButton, settings.sliderContainerSettings.cssWhenButtonsBothOnMax);
	};

	const hideButtons = () => {
		nextButton && expressAddClass(nextButton, settings.sliderContainerSettings.cssWhenButtonsBothOnMax);
		prevButton && expressAddClass(prevButton, settings.sliderContainerSettings.cssWhenButtonsBothOnMax);
	};

	let ioSlider: IntersectionObserver | undefined = createIntersectionObserver();

	// init slider
	init(element, settings.replaceWithElement);

	// return
	return {
		RemoveSliderElement: removeSliderElement,
		RemoveSliderElementOnIndex: removeSliderElementOnIndex,
		AddSliderElement: addSliderElement,
		Previous: previousEvent,
		Next: nextEvent,
		JumpIntoView: jumpIntoView,
		Settings: settings,
		GetCurrentSlide: getCurrentElement,
		GetAllSlides: getAllElements,
		GetSliderElement: () => sliderEl,
		ShowButtons: showButtons,
		HideButtons: hideButtons,
		Dispose: dispose,
		AddHandlers: addHandlers
	};
}
